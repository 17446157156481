


















import { Component, Vue } from "vue-property-decorator";
import api from "@/api/index";
import { ProjectBasicDetailUpdateDto } from "@/api/appService";
import Ueditor from "@/components/Ueditor/index.vue";

@Component({
  components: {
    Ueditor,
  },
})
export default class ProjectResultEdit extends Vue {
  submitting = false;

  description = "";

  projectId = 0;

  projectDto: ProjectBasicDetailUpdateDto = {};

  created() {
    if (this.$route.params.projectId) {
      this.projectId = Number(this.$route.params.projectId);
    }
    if (!this.projectId) {
      this.$message.error("缺少projectId参数");
      return;
    }
    this.loadProject();
  }

  loadProject() {
    api.project
      .getProjectBasicDetail({ projectId: this.projectId })
      .then((res) => {
        this.projectDto = { ...res };
        this.description = this.projectDto.projectResult ?? "";
      });
  }

  handleSubmit() {
    this.projectDto.projectResult = this.description;
    this.submitting = true;
    api.project
      .updateProjectBasicDetail({ body: this.projectDto })
      .then((res) => {
        this.submitting = false;
        this.$message.success("保存成功");
        this.$router.back();
      })
      .catch((res) => {
        this.submitting = false;
      });
  }

  cancel() {
    this.$router.back();
  }
}
